<template>
  <div class="item">
    <el-row class="header">
      <el-col :span="6" class="text-center">商品信息</el-col>
      <el-col :span="2" class="text-center">单价</el-col>
      <el-col :span="2" class="text-center">数量</el-col>
      <el-col :span="5" class="text-center">单品优惠券</el-col>
      <el-col :span="5" class="text-center">单品促销</el-col>
      <el-col :span="2" class="text-right">单品促销折扣</el-col>
      <el-col :span="2" class="text-right">总金额</el-col>
    </el-row>
    <el-row class="main">
      <div class="shopInfo">
          <el-col :span="6">
            <div class="leftContent" @click="jumpDetail()">
              <img :src="goodsDetails.goodsPic" alt="" />
            </div>
            <div class="rightContent">
              <el-col :span="24" class="nameTit">
                <span class="nameBox" :title="goodsDetails.goodsName"
                  >{{ goodsDetails.goodName }}</span
                >
              </el-col>
                <el-col>
                 <span class="gift">[药豆商品]</span>
              </el-col>
            </div>
          </el-col>
          <el-col :span="2" class="text-center goodsBox">{{goodsDetails.goodPoints}}药豆</el-col>
          <el-col :span="2" class="text-center goodsBox">{{goodsDetails.goodsCount}}</el-col>
          <el-col :span="5" class="text-center goodsBox">
            <span>无</span>
          </el-col>
          <el-col :span="5" class="text-center orderPromotionsBox">
            <div
              class="orderPromotionsMsg"
              style="text-align:center;text-indent:0px"
            >
              无
            </div>
          </el-col>

          <el-col :span="2" class="text-center goodsBox">无</el-col>
          <el-col :span="2" class="text-right goodsBox">无</el-col>
        </div>
    </el-row>
  </div>
</template>

<script>
// const OrderItem = () => import("components/order/OrderItem.vue");
// GiftItem
// const GiftItem = () => import("components/order/GiftItem.vue");
export default {
  name: "IntegralMallList",
  data() {
    return {
      ordereCouponSelected: "",
      goodsDetails:''
    };
  },
  props: {
    goodsList: {
      type: Object,
    },
  },
  created() {
    console.log('列表',this.goodsList);
    this.goodsDetails=this.goodsList
  },
  computed: {},
  methods: {
    jumpDetail(){
      this.$router.push({
         name: "IntegralMallDetail",
         query: { id: this.goodsList.pointsRedeemProductId},
      });
    }

  },
  components: {

  },
};
</script>

<style lang="less" scoped>
@import "~style/index.less";
.item {
  padding: 0 20px 20px 20px;
  .header {
    padding: 20px;
    background: #f6f6f6;
    color: #666666;
    font-size: 13px;
  }
  .main {
    width: 100%;
    .shopInfo {
      clear: both;
      margin-top: 4px;
      background: rgba(240, 225, 210, 0.2);
      padding: 10px 20px;
      float: left;
      width: calc(100% - 40px);
      .leftContent {
        float: left;
        width: 80px;
        height: 80px;
        margin-top: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .rightContent {
        float: left;
        margin-left: 8px;
        width: calc(100% - 88px);
          .gift {
            color: #ff9999;
            font-size:13px;
            vertical-align: super;
          }
        .nameTit {
          color: #333;
          font-size: 13px;
          font-weight: bold;
          margin-top: 20px;
          margin-bottom: 10px;
          .nameBox {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            max-width: 140px;
            font-size: 14px;
          }
          
        }
      
        .specMsg {
          font-size: 12px;
          color: #666;
          line-height: 20px;
          margin-bottom: 5px;
          span:first-child {
            margin-right: 5px;
          }
        }
        .dateMsg {
          font-size: 12px;
          color: #666;
          line-height: 20px;
          margin-bottom: 5px;
          span:first-child {
            margin-right: 5px;
          }
        }
      }
      .goodsBox {
        line-height: 80px;
        font-size: 14px;
        color: #666;
      }
      .orderPromotionsBox {
        height: 80px;
        justify-content: center; //子元素水平居中
        align-items: center; //子元素垂直居中
        display: -webkit-flex;
        .orderPromotionsMsg {
          font-size: 14px;
          color: #666;
          text-align: left;
          text-indent: 20px;
        }
      }
    }

    .payMethod {
      border: 1px solid #ccc;
      padding: 10px;
    }
  }
}
</style>
